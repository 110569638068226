import axios from 'axios'
import getEnv from '@/utils/env'

import NProgress from 'nprogress'

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const apiClient = axios.create({
  baseApiUrl: getEnv('VUE_APP_BASE_API_URL'),
  baseURL: getEnv('VUE_APP_BASE_API_URL'),
  withCredentials: false
})

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  NProgress.done()
  return response
})

export default apiClient
