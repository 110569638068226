import CoreService from '@/services/shared/CoreService.js'

export const namespaced = true

export const state = {
  percentCompleted: 0
}

export const mutations = {}

export const actions = {
  getBatchFile({}, { monitoring, batchId }) {
    let url = 'transactional/get_batch_file/'
    if (monitoring) url = 'monitoring/get_batch_file/'
    CoreService.downloadFile({ url: url, fileOptions: { batch_id: +batchId }, filename: 'file' + batchId + '.csv' })
  },
  getBatchReport({}, { batchId }) {
    let url = 'transactional/get_batch_report/'
    CoreService.downloadFile({ url: url, fileOptions: { batch_id: +batchId }, filename: 'file' + batchId + '.xlsx' })
  },
  getReport({}, { reportOptions }) {
    // console.log(reportOptions)
    let url = 'monitoring/get_report/'
    CoreService.downloadFile({ url: url, fileOptions: reportOptions, filename: 'report.xls' })
  },
  uploadActionDoc({ state }, { options }) {
    state.percentCompleted = 0
    return BryceService.send({ method: 'uploadActionDoc', errorMessage: 'uploading file', params: { fd: options.fd } }).then(results => {
      if (results) return results.staged_file_id
    })
  }
}

export const getters = {
  percentUploaded: state => {
    return state.percentCompleted
  }
}
