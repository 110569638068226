<template>
  <div class="card">
    <div v-if="licenseResult">
      <table class="table is-striped">
        <tbody>
          <tr>
            <th></th>
            <th>Customer Provided</th>
            <th>Board Reported</th>
          </tr>
          <tr v-if="licenseResult.organization_name">
            <th>Name</th>
            <td>{{ licenseResult.organization_name }}</td>
            <td>{{ orPending(licenseResult.organization_name) }}</td>
          </tr>
          <tr v-if="licenseResult.first_name">
            <th>First Name</th>
            <td>{{ licenseResult.input_first_name }}</td>
            <td>{{ orPending(licenseResult.first_name) }}</td>
          </tr>
          <tr v-if="licenseResult.middle_name">
            <th>Middle Name</th>
            <td>{{ licenseResult.input_middle_name }}</td>
            <td>{{ orPending(licenseResult.middleName) }}</td>
          </tr>
          <tr v-if="licenseResult.last_name">
            <th>Last Name</th>
            <td>{{ licenseResult.input_last_name }}</td>
            <td>{{ orPending(licenseResult.last_name) }}</td>
          </tr>
          <tr>
            <th>License Type</th>
            <td>{{ licenseResult.input_license_type }}</td>
            <td>{{ orPending(licenseResult.license_type) }}</td>
          </tr>
          <tr>
            <th>License State</th>
            <td>{{ licenseResult.license_state }}</td>
            <td>{{ orPending(licenseResult.license_state) }}</td>
          </tr>
          
          <tr v-if="licenseResult.license_multistate == 0 && (licenseResult.last_check_at || licenseResult.completed_at)">
            <th>Multi-State?</th>
            <td></td>
            <td>NO</td>
          </tr>
          <tr v-if="licenseResult.license_multistate == 1">
            <th>Multi-State?</th>
            <td></td>
            <td>YES</td>
          </tr>
          <tr v-if="licenseResult.license_multistate == 2">
            <th>Multi-State?</th>
            <td></td>
            <td>Not Applicable</td>
          </tr>


          <tr>
            <th>License #</th>
            <td>{{ licenseResult.input_license_number }}</td>
            <td>{{ orPending(licenseResult.license_number) }}</td>
          </tr>
          <tr>
            <th>License Status</th>
            <td>-</td>
            <td>{{ orPending(licenseResult.license_status) }}</td>
          </tr>
          <tr>
            <th>Found Status</th>
            <td>-</td>
            <td>{{ orPending(licenseResult.found_status) }}</td>
          </tr>
          <tr>
            <th>Expiration</th>
            <td>-</td>
            <td>
              <span v-if="licenseResult.license_status != 'PENDING'">{{ licenseResult.license_expiration | shortDate
                }}</span>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <th>Issued</th>
            <td>-</td>
            <td>
              <span v-if="licenseResult.license_status != 'PENDING'">{{ licenseResult.license_issued | shortDate
                }}</span>
            </td>
          </tr>
          <tr>
            <th>Details</th>
            <td colspan="2">
              <pre style="white-space: pre-wrap">{{ orPending(licenseResult.license_detail) }}</pre>
            </td>
          </tr>
          <tr v-if="licenseResult.license_snapshot">
            <th>Snapshot</th>
            <td colspan="2"></td>
          </tr>
          <tr v-if="licenseResult.license_snapshot">
            <td colspan="3">
              <img style="width: 100%;"
                :src="baseApiUrl + '/transactional/get_license_snapshot/' + licenseResult.license_snapshot + '/'" />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="includeForm">
        <b-field v-if="isMonitoring" label="Notes"
          :type="licenseResult && licenseResult.monitoring_alert_notes == notes ? '' : 'is-info'">
          <span v-if="false">|A3|</span><b-input v-model="licenseResult.monitoring_alert_notes" maxlength="200" rows="2"
            type="textarea"></b-input>
        </b-field>
        <b-field v-else label="Notes" :type="licenseResult && licenseResult.license_notes == notes ? '' : 'is-info'">
          <span v-if="false">|A4|</span><b-input v-model="licenseResult.license_notes" maxlength="200" rows="2"
            type="textarea"></b-input>
        </b-field>
        <button v-if="isMonitoring" type="submit" class="button is-primary" @click="onSubmitOptions(licenseResult)"
          :disabled="licenseResult && licenseResult.monitoring_alert_notes == notes">Update</button>
        <button v-else type="submit" class="button is-primary" @click="onSubmitOptions(licenseResult)"
          :disabled="licenseResult && licenseResult.license_notes == notes">Update</button>
      </div>
    </div>
    <div v-else class="box">No Results</div>
  </div>
</template>

<script>
import getEnv from '@/utils/env'

export default {
  props: {
    includeForm: {
      type: Boolean,
      default: true
    },
    licenseResultByProp: {
      type: Object,
      default: null
    },
    isMonitoring: {
      type: Boolean,
      default: false,
      required: true
    },
    productOrderId: {
      type: Number,
      default: null
    },
    subjectId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      baseApiUrl: getEnv('VUE_APP_BASE_API_URL'),
      licenseResult: '',
      notes: ''
    }
  },
  computed: {},
  created() {
    if (this.licenseResultByProp) {
      this.licenseResult = this.licenseResultByProp
      this.notes = this.licenseResult.license_notes
    } else {
      if (this.isMonitoring) {
        this.$store.dispatch('license/fetchLicenseResult', { subjectId: this.subjectId, monitoringProductId: this.productOrderId }).then(licenseResult => {
          this.licenseResult = licenseResult
          this.notes = ''
          this.licenseResult.ack = licenseResult.ack_at ? true : false
          if (licenseResult) {
            this.notes = this.licenseResult.monitoring_alert_notes
          }
        })
      } else {
        this.$store.dispatch('license/fetchLicenseResult', { subjectId: this.subjectId, monitoringProductId: this.productOrderId }).then(licenseResult => {
          this.licenseResult = licenseResult
          this.notes = ''
          this.licenseResult.ack = licenseResult.ack_at ? true : false
          if (licenseResult) {
            this.notes = this.licenseResult.monitoring_alert_notes
          }
        })
      }
    }
  },
  methods: {
    orPending(value) {
      if (this.licenseResult.license_status == 'PENDING') {
        return 'PENDING'
      }
      return value
    },
    onSubmitOptions(licenseResult) {
      if (this.isMonitoring) {
        this.$store.dispatch('action_result/alertUpdate', { alertId: licenseResult.monitoring_alert_id, subjectId: this.subjectId, notes: licenseResult.monitoring_alert_notes }).then(() => {
          this.notes = licenseResult.monitoring_alert_notes
        })
      } else {
        this.$store.dispatch('license/licenseResultUpdate', { licenseResultId: licenseResult.license_result_id, notes: licenseResult.license_notes, monitoring: this.isMonitoring }).then(() => {
          this.notes = licenseResult.license_notes
        })
      }
    }
  }
}
</script>

<style scoped>
pre {
  white-space: pre-wrap;
}
</style>
