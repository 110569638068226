import axios from 'axios'
// console.log(process.env)
import store from '@/store/bryce'
// } else {
//   import store from '@/store/zion'
// }
import FileSaver from 'file-saver'
import apiClient from '@/services/shared/AxiosService.js'

const coreMethods = {
  confirmPassword(options) {
    axios.defaults.headers.common['Authorization'] = ''
    return apiClient({ method: 'post', url: 'user/confirm_password_reset_link/', data: options })
  },
  getAuthValid() {
    return apiClient({ method: 'get', url: 'user/auth_valid/', data: {} })
  },
  getUsers(options) {
    if (options['customerId']) return apiClient({ method: 'post', url: 'reseller/get_users/', data: { customer_id: options['customerId'] } })
    else return apiClient({ method: 'post', url: 'user/get_users/', data: {} })
  },
  proxyLogin(options) {
    return apiClient({ method: 'post', url: 'user/proxy_authenticate/', data: options })
  }
}

export default {
  send(options) {
    // console.log(options)
    return coreMethods[options['method']](options['params'])
      .then(response => {
        if (response.data.status == 'OK') {
          return response.data
        } else if (response.data.status == 'INVALID_TOKEN') {
          // console.log('Core Token expired...logging out.')
          store.dispatch('auth_user/authExpire', {}, { root: true })
          return null
        } else {
          return response.data
        }
      })
      .catch(error => {
        const notification = { type: 'error', message: 'There was an error ' + options['message'] + ': ' + error.message + '. Calling: ' + options['method'] }
        store.dispatch('notification/add', notification, { root: true })
      })
  },
  downloadFile(options) {
    apiClient({
      method: 'post',
      url: options['url'],
      responseType: 'blob',
      data: options['fileOptions']
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' })
        FileSaver.saveAs(blob, options['filename'])
      })
      .catch(error => {
        const notification = { type: 'error', message: 'There was an error ' + options['message'] + ': ' + error.message + '. Calling: ' + options['method'] }
        store.dispatch('notification/add', notification, { root: true })
      })
  },
  getAuthUser(username, password) {
    let body = JSON.stringify({ username, password })
    return apiClient({
      method: 'post',
      url: 'user/authenticate/',
      data: body
    })
  },
  logout() {
    this.removeToken()
    return apiClient({ method: 'post', url: 'user/logout/', data: {} })
  },
  rescuePassword(username, subdomain, domain) {
    let body = JSON.stringify({ username, subdomain, domain })
    return apiClient({
      method: 'post',
      url: 'user/send_password_reset_link/',
      data: body
    })
  },
  removeToken() {
    delete axios.defaults.headers.common['Authorization']
  },
  setToken(token) {
    axios.defaults.headers.common['Authorization'] = token
  }
}
